<template>
  <van-tabbar
    v-model="activeNow"
    active-color="rgb(24, 129, 210)"
    :border="false"
  >
    <van-tabbar-item v-for="(item, index) in list" :key="index" :to="item.to">
      <div class="item">
        <img :class="item.to == 'Donate'?'mid-button':''"
          :src="active !== item.to ? item.icon : item.activeIcon"
        />
      </div>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: String,
      default: 'NewProduct'
    }
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig'])
  },
  watch: {
    active(newVal) {
      this.activeNow = newVal
    },
    lang() {
      this.changeLang()
    }
  },
  data() {
    return {
      activeNow: 'NewProduct',
      list: []
    }
  },
  mounted() {
    console.log(this.basicConfig)
    this.activeNow = this.active
    this.changeLang()
  },
  methods: {
    changeLang() {
      this.list = []
      this.list.push({
        icon: require('../assets/newImg/home_no.png'),
        activeIcon: require('../assets/newImg/home_in.png'),
        to: 'Home'
      })
      this.list.push({
        icon: require('../assets/newImg/direct_no.png'),
        activeIcon: require('../assets/newImg/direct_in.png'),
        to: 'Direct'
      })
      this.list.push({
        icon: require('../assets/newImg/donate.png'),
        activeIcon: require('../assets/newImg/donate.png'),
        to: 'Donate'
      })
      this.list.push({
        icon: require('../assets/newImg/audit_no.png'),
        activeIcon: require('../assets/newImg/audit_in.png'),
        to: 'Audit'
      })
      this.list.push({
        icon: require('../assets/newImg/my_no.png'),
        activeIcon: require('../assets/newImg/my_in.png'),
        to: 'User'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.van-tabbar
  background-color: rgba(0,0,0,0);
  background-image: url(../assets/newImg/tabg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 61px;
  >>> .van-tabbar-item--active
    background: none;
  .item
    display flex
    flex-direction column
    justify-content center
    align-items center
    span
      text-align: center
      white-space: nowrap
      font-size: 13px
 .mid-button
   width: 48px;
   height: 48px;
   margin-bottom: 8px
  img
    margin-top: 10px
    width 27px
    height 25px
</style>
